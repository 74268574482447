<template>
  <transition name="fade">
    <div class="c-app pdf-page">
      <YardBlocksPdf
        v-if="pageInfo.type == 'yard-blocks'"
        :details="pageInfo.details"
        :vesselRoute="pageInfo.vesselRoute"
        :legend = "legend"
        :positions="pageInfo.positions"
      />
    </div>
  </transition>
</template>

<script>
import YardBlocksPdf from "@/pages/yards/pdf/yard-blocks-pdf";
import debounce from 'lodash/debounce';

//Data
function data() {
  return {
    pageInfo: {
      vesselRoute: '',
      bayCode: '',
      positions: [],
      currentBay: null,
      details: {},
    },
    docTitle: '',
    legend: []
  };
}

//Created
function created() {
  let info = JSON.parse(localStorage.getItem('pageInfo'));

  if(!info) {
    window.close();
    return;
  }

  this.pageInfo = {...this.pageInfo, ...info};

  this.getDataFromService();

  localStorage.removeItem('pageInfo');
  
  window.addEventListener("beforeprint", function(event) {
  });
  window.addEventListener("afterprint", function(event) {
    close();
  });
}

//Methods:
function getDataFromService() {

this.$http.ejecutar('GET', 'YardSlotConfigStatus-list')
.then(response => {
  this.legend = response.data.data || [];
}).catch( err => {
  this.$notify({
    group: 'container',
    title: '¡Error!',
    text: err,
    type: "error"
  });
});
}


export default {
  name: "Reporte-Yard-Blocks-PDF",
  components: {
    YardBlocksPdf,
  },
  data,
  created,
  mounted:debounce(function () {
    this.$nextTick(() => {
      if(this.pageInfo.type == 'yard-blocks')
        window.print();
    })
  }, 500),
  methods: {
    getDataFromService,
  },
  computed: {
  },
};
</script>

<style scoped>
.pdf-page {
  width: 100%;
  min-height: 100vh;

  display: flex;
  justify-content: center;
  z-index: 2;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>